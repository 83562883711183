import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Dashboard",
          path: "dashboard/:lat?/:long?/:date?",
          component: () => import("@/views/Starter"),
        },
        {
          name: "RischioAlluvioni",
          path: "/rischio-alluvioni/:lat?/:long?/:date?",
          component: () => import("@/views/Starter"),
          props:true
        },
        {
          name: "RischioIncendi",
          path: "/rischio-incendi/:lat?/:long?",
          component: () => import("@/views/ui-elements/Avatar"),
        },
        {
          name: "Videotrappole",
          path: "/video-trappole",
          component: () => import("@/views/ui-elements/Badge"),
        },
        {
          name: "EdificiNonAccatastati",
          path: "/edifici-non-accatastati",
          component: () => import("@/views/ui-elements/Breadcrumb"),
        },
        {
          name: "ProduzioneEnergetica",
          path: "/produzione-energetica-eolica",
          component: () => import("@/views/ui-elements/Buttons"),
        },
        {
          name: "ProduzioneEnergeticaSolare",
          path: "/produzione-energetica-solare",
          component: () => import("@/views/ui-elements/ButtonGroup"),
        },

        {
          name: "Cards",
          path: "/ui-elements/cards",
          component: () => import("@/views/ui-elements/Cards"),
        },

        {
          name: "Dropdown",
          path: "/ui-elements/dropdown",
          component: () => import("@/views/ui-elements/Dropdown"),
        },
        {
          name: "Popover",
          path: "/ui-elements/popover",
          component: () => import("@/views/ui-elements/Popover"),
        },

        {
          name: "Toasts",
          path: "/ui-elements/toasts",
          component: () => import("@/views/ui-elements/Toasts"),
        },
        {
          name: "Tooltip",
          path: "/ui-elements/tooltip",
          component: () => import("@/views/ui-elements/Tooltip"),
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/Starter"),
    },
  ],
});

export default router;
