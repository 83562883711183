import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueYouTubeEmbed from 'vue-youtube-embed'
import Embed from 'v-video-embed'

Vue.config.productionTip = false;
Vue.use(VueYouTubeEmbed);
Vue.use(Embed);

new Vue({ store, router, render: (h) => h(App) }).$mount("#app");
